<template>
  <div>
    <div class="d-flex w-100 justify-content-between">
      <b-card no-body class="rounded-0">
        <b-card-header class="bg-white font-weight-bold">Fatura Bilgileri</b-card-header>
        <b-card-body v-if="form.adres_bilgileri">
          <div class="int-kargo-fisi" v-if="Object.keys(form).length > 0">
            <div class="int-kargo-desc">
              <div class="d-flex">
                <span>{{ form.uye_ad }} {{ form.uye_soyad }}</span>
              </div>
              <div class="d-flex">
                <span>
                  {{ form.adres_bilgileri.kargo.adres }} - {{ form.adres_bilgileri.kargo.ilce }} /
                  {{ form.adres_bilgileri.kargo.sehir }} / {{ form.adres_bilgileri.kargo.ulke }}
                </span>
              </div>
              <div class="d-flex">
                <span>{{ form.adres_bilgileri.kargo.telefon_1 }}</span>
              </div>
              <div class="d-flex">
                <span>{{ form.adres_bilgileri.kargo.telefon_2 }}</span>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>

      <b-card no-body class="rounded-0">
        <b-card-header class="bg-white font-weight-bold">Firma Bilgileri</b-card-header>
        <b-card-body>
          <div class="int-kargo-fisi" v-if="Object.keys(firma).length">
            <div class="int-kargo-desc">
              <div class="d-flex">
                <span>{{ firma.yetkili_ad_soyad }}</span>
              </div>
              <div class="d-flex">
                <span>
                  {{ firma.adres }}
                </span>
              </div>
              <div class="d-flex">
                <span>{{ firma.telefon }}</span>
              </div>
              <div class="d-flex">
                <span>{{ firma.e_mail }}</span>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <b-card no-body class="rounded-0 my-2">
      <b-card-header class="bg-white font-weight-bold">Sipariş Bilgileri</b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex justify-content-between align-items-center pb-4">
              <span>Sipariş Numarası</span>
              <span class="font-weight-bold">{{ form.k_no }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center pb-4">
              <span>Üye Adı Soyadı</span>
              <span class="font-weight-bold">{{ form.uye_ad }} {{ form.uye_soyad }} </span>
            </div>
            <div class="d-flex justify-content-between align-items-center pb-4">
              <span>Sipariş Tarih Saat</span>
              <span class="font-weight-bold">{{ form.tarih | momentFull }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center pb-4">
              <span>Ödeme Tipi</span>
              <span class="font-weight-bold">
                {{
                  form.odeme_yontemi == 'kapida_odeme'
                    ? 'Kapıda Ödeme'
                    : form.odeme_yontemi == 'havale_eft'
                    ? 'Banka Havalesi'
                    : 'Kredi Kartı Ödeme'
                }}
              </span>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-row>
      <b-col cols="12">
        <b-card no-body class="rounded-0">
          <b-card-header class="bg-white font-weight-bold">Sipariş Notu</b-card-header>
          <b-card-body>
            {{ form.sepet_notu }}
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="rounded-0">
          <b-card-header class="bg-white font-weight-bold d-flex justify-content-between align-items-center">
            <span>Sipariş Ürünleri</span>
            <b-button-group>
              <b-button
                class="printButtonVisible mr-2"
                squared
                size="sm"
                variant="info"
                @click="sabitKuraCevir = !sabitKuraCevir"
              >
                Sabit Kura Cevir
              </b-button>
              <b-button class="printButtonVisible" squared size="sm" variant="info" @click="handlerPrint"> Yazdır </b-button>
            </b-button-group>
          </b-card-header>
          <b-card-body>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="text-left font-weight-bold h6">#Ürün</th>
                  <th scope="col" class="text-right font-weight-bold h6" width="15%">Fiyat</th>
                  <th scope="col" class="text-center font-weight-bold h6" width="15%">Miktar</th>
                  <th scope="col" class="text-right font-weight-bold h6" width="15%">Kdv</th>
                  <th scope="col" class="text-right font-weight-bold h6" width="15%">Tutar</th>
                </tr>
              </thead>
              <tbody v-if="(form.sepet_toplam && form.kur_turu == form.secilen_kur) || sabitKuraCevir">
                <UrunSabitItem v-for="(urun, i) in form.sepet_urunler" :key="i" :urun="urun" :noimg="true" />
              </tbody>
              <tbody v-else-if="(form.kur_sepet_toplam && form.kur_turu != form.secilen_kur) || !sabitKuraCevir">
                <UrunKurItem v-for="(urun, i) in form.sepet_urunler" :key="i" :urun="urun" :noimg="true" />
              </tbody>
              <UrunSabitToplam :form="form" v-if="form.kur_turu == form.secilen_kur || sabitKuraCevir" />
              <UrunKurToplam :form="form" v-else />
            </table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <table class="w-100">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { useRouter } from '@/libs/utils';
import store from '@/store';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import AdresItem from './component/AdresItem.vue';
import UrunSabitItem from './component/UrunSabitItem.vue';
import UrunKurItem from './component/UrunKurItem.vue';
import UrunSabitToplam from './component/UrunSabitToplam.vue';
import UrunKurToplam from './component/UrunKurToplam.vue';
export default defineComponent({
  components: {
    AdresItem,
    UrunSabitItem,
    UrunKurItem,
    UrunSabitToplam,
    UrunKurToplam,
  },
  setup() {
    const expo = {};
    const { route } = useRouter();
    expo.sabitKuraCevir = ref(false);
    expo.form = ref({});
    expo.firma = ref({});

    onMounted(async () => {
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        await store.dispatch('firmaBilgiGetir').then((res) => {
          if (res.data.data !== null) {
            expo.firma.value = res.data.data;
          }
        });
        await store.dispatch('eTicSiparisFindOne', k_no).then((res) => {
          expo.form.value = res;
        });
      }

      if (Object.keys(expo.firma.value).length > 0 && Object.keys(expo.form.value).length > 0) window.print();
    });

    expo.handlerPrint = () => {
      window.print();
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
@media print {
  .printButtonVisible {
    display: none;
  }
  @page {
    size: A4;
  }
}
.int-kargo-fisi {
  .int-kargo-title {
    border-bottom: 4px solid rgb(60, 60, 60);
  }
}
</style>
