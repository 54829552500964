<template>
  <div>
    <p><i class="fad fa-map-pin" /> {{ item.adres }}</p>
    <span> <i class="fad fa-phone" /> {{ item.telefon_1 }}</span>
    <span> <i class="fad fa-phone" /> {{ item.telefon_2 }}</span>
    <br />
    <span>
      <i class="fad fa-city" /> {{ item.sehir }} | {{ item.ilce }} |
      {{ item.ulke }}
    </span>
    <br />
    <span v-if="item.turu == 'bireysel'"> <i class="fad fa-passport" /> {{ item.tc_kimlik }} </span>
    <span v-else>
      <i class="fad fa-passport" /> {{ item.vergi_dairesi }} |
      {{ item.vergi_no }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    item: {
      type: Object,
      requried: true,
      default: {},
    },
  },
  setup(props, context) {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
